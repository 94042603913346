export const test = '/test'
// information type
export const informationTypeListApi = '/configuration/information-type/list'
export const informationTypeSaveApi = '/configuration/information-type/save'
export const informationTypeStatusApi = '/configuration/information-type/toggle-status'

// information menu
export const infomenuListApi = '/configuration/information-menu/list'
export const infomenuListOrgWiseApi = '/configuration/information-menu/org-menu-list'
export const infomenuSaveApi = '/configuration/information-menu/save'
export const infomenuUpdateApi = '/configuration/information-menu/update'
export const infomenuStatusApi = '/configuration/information-menu/toggle-status'

// soil type
export const soilTypeListApi = '/configuration/soil-type/list'
export const soilTypeSaveApi = '/configuration/soil-type/save'
export const soilTypeStatusApi = '/configuration/soil-type/toggle-status'

// krishibid
export const krishibidListApi = '/configuration/virtual-krishibid/list'
export const krishibidSaveApi = '/configuration/virtual-krishibid/save'
export const krishibidStatusApi = '/configuration/virtual-krishibid/toggle-status'
// visiting hour
export const visitingHourListApi = '/configuration/visiting-hour/list'
export const visitingHourSaveApi = '/configuration/visiting-hour/save'
export const visitingHourStatusApi = '/configuration/visiting-hour/toggle-status'
// ======CropType===========
export const cropType = '/configuration/crop-type'
export const cropTypeList = cropType + '/list'
export const cropTypeStore = cropType + '/store'
export const cropTypeUpdate = cropType + '/update'
export const cropTypeToggleStatus = cropType + '/toggle-status'

// =========Crops=============
export const crop = '/configuration/crop'
export const cropList = crop + '/list'
export const cropStore = crop + '/store'
export const cropUpdate = crop + '/update'
export const cropToggleStatus = crop + '/toggle-status'
// ============Land-and-soil-Characteristic============
export const landAndSoilChar = '/configuration/land-and-soil-characteristic'
export const landAndSoilCharList = landAndSoilChar + '/list'
export const landAndSoilCharStore = landAndSoilChar + '/store'
export const landAndSoilCharUpdate = landAndSoilChar + '/update'
export const landAndSoilCharToggleStatus = landAndSoilChar + '/toggle-status'

// Off days
export const offDaysListApi = '/configuration/off-days/list'
export const offDaysStoreApi = '/configuration/off-days/store'
export const offDaysUpdateApi = '/configuration/off-days/update'
export const offDaysToggleStatusApi = '/configuration/off-days/toggle-status'

// Tour operators
export const tourOperatorListApi = '/configuration/tour-operator/list'
export const tourOperatorStoreApi = '/configuration/tour-operator/store'
export const tourOperatorUpdateApi = '/configuration/tour-operator/update'
export const tourOperatorToggleApi = '/configuration/tour-operator/toggle-status'

// Dashboard
export const totalMuseumApi = 'dashboard/total-museum'
export const totalCropApi = 'dashboard/total-crop'
export const totalPestApi = 'dashboard/total-pest'
export const totalSoilApi = 'dashboard/total-soil'
export const totalGalleryImageApi = 'dashboard/total-gallery-image'
export const museumVisitRequestApi = 'dashboard/museum-visit-request'

// Report Heading
export const reportHeadingList = '/configuration/report-heading/list'
export const reportHeadingStore = '/configuration/report-heading/store'
export const reportHeadingUpdate = '/configuration/report-heading/update'
export const reportToggleStatus = '/configuration/report-heading/toggle-status'
